import Container_VerticalRibbonAreaComponent from '../components/Container/viewer/skinComps/VerticalRibbonArea/VerticalRibbonArea.skin';


const Container_VerticalRibbonArea = {
  component: Container_VerticalRibbonAreaComponent
};


export const components = {
  ['Container_VerticalRibbonArea']: Container_VerticalRibbonArea
};

